import { Avatar, AvatarGroup, Button, Card, CardContent, CardHeader, Dialog, DialogContent, Grid, IconButton, ListItem, ListItemButton, ListItemIcon, Menu, MenuItem, TextField } from "@mui/material"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { useContext, useEffect, useMemo, useState } from "react"
import { UserContext } from "../../context/UserContext"
import { editCommunity, getCommunity } from "../../controllers/community/community"
import { useNavigate, useParams } from "react-router"
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query"
import { Add, ChevronRight, MoreVert } from "@mui/icons-material"
import MemberRequests from "../../components/community/member_requests"
import { JoinCommunityButton } from "../../components/community/join_community.button"
import { PostMedia } from "../../components/post/card/PostMedia"
import { createComment } from "../../controllers/posts/comments"
import { getSinglePost } from "../../controllers/posts/posts"
import SlimPostCard from "../../components/post/SlimPostCard"
import MembersPopUpList from "../../components/members_popup.list"
import { NoPostsIcon } from "../../components/NoPosts.Icon"
import { CategoryDisplay } from "../../components/category.display"
import { getAccount } from "../../controllers/accounts/accounts"
import { getCommunityEvents } from "../../controllers/event/event"

export const ShowCommunity = () => {
    const { id } = useParams()
    const userDetails = useContext(UserContext)
    let token = userDetails?.token
    
    const [media_ids, setMediaIds] = useState([])
    const [imageUrls, setImageUrls] = useState([])
    const [open, setOpen] = useState(false)
    const [members, setMembers] = useState([])
    const [membersReq, setMembersReq] = useState([])
    const [leaders, setLeaders] = useState([])
    const queryClient = new QueryClient()
    const mutationEdit = useMutation({
        mutationFn: editCommunity,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['editCommunity'] })
        setOpen(false)
        document.getElementById("status").value = ''
        setImageUrls([])
        },
    })

    const [allContent, setAllContent] = useState([])
    const { data:community, refetch } = useQuery({ queryKey: ['getSingleCommunity'], queryFn: () => getCommunity(token, id) })
    const { data:communityEvents } = useQuery({ queryKey: ['getCommunityEvents'], queryFn: () => getCommunityEvents(token, id) })

    let single_community = useMemo(()=> community?.community ?? {}, [community?.community]) 

    useEffect(() => {
      single_community?.member?.forEach(async (member) => {
        const acct = await getAccount(member, token)
        setMembers(ids => [...ids, acct])
      })
      single_community?.member_request?.forEach(async (memberReq) => {
        const acct = await getAccount(memberReq, token)
        setMembersReq(ids => [...ids, acct])
      })
      single_community?.leader?.forEach(async (leader) => {
        const acct = await getAccount(leader, token)
        setLeaders(ids => [...ids, acct])
      })
    }, [single_community, token])
    

    useEffect(() => {
        setAllContent([])

        const getposts = async (postID) => {
            let id = postID
            let local_post = await getSinglePost(id, token)
            // Delete record from community statuses not found
            if(local_post?.error === "Record not found"){

                editCommunity({
                    id:single_community?._id, 
                    body:{statuses:single_community?.statuses?.filter(item => item !== postID)},
                    token: userDetails?.token
                })
            }

            setAllContent(allContent => [...allContent, local_post])
        }

        single_community?.statuses?.forEach(item => getposts(item))
    }, [single_community?.statuses, token, single_community?._id, userDetails?.token])

    const mutation = useMutation({
        mutationFn: createComment,
        onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createComment'] })
        mutationEdit.mutate({
            id:single_community?._id, 
            body:{statuses:[...single_community.statuses, data.id,]},
            token:userDetails?.token
        })

        },
    })

    const send_reply = () => {
        mutation.mutate({
        body:{
            status: document.getElementById("status").value,
            visibility:"unlisted",
            media_ids
        },
        token
        })
    }

    let navigate = useNavigate()
    useEffect(() => {
        refetch()
    })

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [membersOpen, setMembersOpen] = useState(false)
    const [leadersOpen, setLeadersOpen] = useState(false)

    return (
        <MainLoggedInLayout>
            <Card>
                <CardHeader
                    title={single_community?.title}
                    subheader={ 
                    <div style={{float:'right', marginTop:-35}}>
                        {!single_community?.member?.includes(userDetails?.id) &&
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openMenu ? 'long-menu' : undefined}
                          aria-expanded={openMenu ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVert />
                        </IconButton>}
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={handleClose}
                        >
                            {single_community?.leader?.includes(userDetails?.id) &&
                                <div>
                                <MenuItem>
                                    <MemberRequests community_id={single_community?._id} members={members} requests={membersReq} />
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Button variant="contained" size="small" onClick={() => navigate("/community/edit/"+single_community?._id)}>Edit</Button>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Button variant="contained" onClick={() => navigate("/event/create/"+single_community?._id)}>Create A Event</Button>
                                </MenuItem>
                                </div>
                            }
                            
                            {single_community?.leader?.includes(userDetails?.id) && single_community?.member?.includes(userDetails?.id) &&
                                <MenuItem onClick={handleClose}>
                                    <JoinCommunityButton community_details={single_community} />
                                </MenuItem>
                            }
                        </Menu>
                    </div>
                    }
                />   
                <img
                    style={{ height: 'auto', width:"100%", maxHeight: 400 }}
                    alt={"community"}
                    title={single_community?.title}
                    src={single_community?.header_img}
                />
                <CardContent>
                    <CategoryDisplay label={single_community?.category} />

                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4}>
                            <p style={{fontWeight:'bold'}}>Leaders</p>
                            <MembersPopUpList leaders={single_community?.leader} community_id={single_community?._id} title={"Leaders List"} members={leaders} open={leadersOpen} setOpen={setLeadersOpen} />
                            <AvatarGroup onClick={() => setLeadersOpen(true)} style={{justifyContent:'flex-end'}} total={single_community?.leader?.length}>
                                {leaders?.map((member, i) =>
                                    <Avatar key={i} alt="Leader" src={member.avatar} />
                                )}
                            </AvatarGroup>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <p style={{fontWeight:'bold'}}>Members</p>
                            <MembersPopUpList leaders={single_community?.leader} community_id={single_community?._id} title={"Members List"} members={members} open={membersOpen} setOpen={setMembersOpen} />
                            <AvatarGroup onClick={() => setMembersOpen(true)} style={{justifyContent:'flex-end'}} total={single_community?.member?.length}>
                                {members?.map((member, i) =>
                                    <Avatar key={i} alt="Member" src={member.avatar} />
                                )}
                            </AvatarGroup>
                        </Grid>
                    </Grid>
                    
                    <div style={{marginBottom:20}}>
                    </div>

                    {single_community?.leader?.includes(userDetails?.id) && single_community?.member?.includes(userDetails?.id) &&
                        <MenuItem onClick={handleClose}>
                            <JoinCommunityButton community_details={single_community} />
                        </MenuItem>
                    }

                    <div>

                    <div>
                        {communityEvents?.event?.length > 0 && <p style={{fontWeight:'bold'}}>Upcoming Events</p>}
                        {communityEvents?.event?.map((event, i) =>
                        <ListItemButton key={i} onClick={() => navigate("/event/show/"+event._id)}>
                            <ListItem>
                                {event.title} 
                            </ListItem>
                            <ListItemIcon>
                            <ChevronRight />
                            </ListItemIcon>
                        </ListItemButton>
                        )}
                    </div>
                    <p style={{fontWeight:'bold'}}>Discussions</p>
                    {single_community?.statuses?.length > 0 ?
                        allContent.map((post, i) =>
                            <SlimPostCard key={i} entity_id={single_community?._id} statuses={single_community?.statuses} content={post} token={token} />
                        )
                        :
                        <NoPostsIcon />
                    }
                        
                    </div>
                </CardContent>
            </Card>

            {( single_community?.leader?.includes(userDetails?.id) || single_community?.member?.includes(userDetails?.id) ) &&
            <div style={{margin:20, justifyContent:'center', display:'flex', position:'absolute', bottom:50}}>
                
                <Dialog
                    open={open}
                    onClose={() => setOpen(!open)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <TextField id="status" fullWidth rows={4} multiline label='Create Post' variant="outlined" />
                        <PostMedia imageUrls={imageUrls} setImageUrls={setImageUrls} media_ids={media_ids} setMediaIds={setMediaIds} token={token} />
                        <Button onClick={send_reply} variant="contained" fullWidth>Upload Post</Button>
                    </DialogContent>
                </Dialog>
                <Button onClick={() => setOpen(true)} color="success" variant="contained"> <Add />Add a post</Button>
            </div>
            }
        </MainLoggedInLayout>
    )
}