import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import { Homepage } from "./views/home";
import { Feed } from "./views/feed";
import { Explore } from "./views/explore";
import { createTheme, ThemeProvider } from "@mui/material";
import { green } from "@mui/material/colors";
import "./App.css"
import { AccountList } from "./views/account/list";
import { AccountShow } from "./views/account/show";
import { AccountEdit } from "./views/account/edit";
import { MessageList } from "./views/message/list";
import { AccountCreate } from "./views/account/create";
import { MessageShow } from "./views/message/show";
import { ErrorPage } from "./views/err.page"
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { PostShow } from "./views/post/show";
import { UserProvider } from "./context/UserContext";
import { NotificationList } from "./views/notification/list";
import { PostCreate } from "./views/post/create";
import { Meet } from "./views/meet";
import { CreateCommunity } from "./views/community/create";
import { DeleteCommunity } from "./views/community/delete";
import { EditCommunity } from "./views/community/edit";
import { ListCommunity } from "./views/community/list";
import { ShowCommunity } from "./views/community/show";
import { CreateEvent } from "./views/event/create";
import { ShowEvent } from "./views/event/show";
import { DeleteEvent } from "./views/event/delete";
import { EditEvent } from "./views/event/edit";
import { ListEvent } from "./views/event/list";
import { CreateForum } from "./views/forum/create";
import { ShowForum } from "./views/forum/show";
import { DeleteForum } from "./views/forum/delete";
import { EditForum } from "./views/forum/edit";
import { ListForum } from "./views/forum/list";

const queryClient = new QueryClient()

const theme = createTheme({
  palette: {
    primary: {
      main: '#4acec8',
      contrastText: 'white'
    },
    secondary: {
      main: green[500],
    },
  },
});



const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/feed",
    element: <Feed />,
  },
  {
    path: "/explore",
    element: <Explore />,
  },
  {
    path: "/members",
    element: <AccountList />,
  },
  {
    path: "/messages",
    element: <MessageList />,
  },
  {
    path: "/messages/:id/:conversation_id",
    element: <MessageShow />,
  },
  {
    path: "/profile/:id",
    element: <AccountShow />,
  },
  {
    path: "/settings",
    element: <AccountEdit />,
  },
  {
    path: "/profile/manage",
    element: <AccountCreate />,
  },
  {
    path: "/post/:id",
    element: <PostShow />,
  },
  {
    path: "/post/create",
    element: <PostCreate />,
  },
  {
    path: "/notifications",
    element: <NotificationList />,
  },
  {
    path: "/meet",
    element: <Meet />,
  },
  {
    path: "/community/create",
    element: <CreateCommunity />,
  },
  {
    path: "/community/show/:id",
    element: <ShowCommunity />,
  },
  {
    path: "/community/delete/:id",
    element: <DeleteCommunity />,
  },
  {
    path: "/community/edit/:id",
    element: <EditCommunity />,
  },
  {
    path: "/community/list",
    element: <ListCommunity />,
  },
  {
    path: "/event/create/:community_id",
    element: <CreateEvent />,
  },
  {
    path: "/event/show/:id",
    element: <ShowEvent />,
  },
  {
    path: "/event/delete/:id",
    element: <DeleteEvent />,
  },
  {
    path: "/event/edit/:id",
    element: <EditEvent />,
  },
  {
    path: "/event/list",
    element: <ListEvent />,
  },
  {
    path: "/forum/create",
    element: <CreateForum />,
  },
  {
    path: "/forum/show/:id",
    element: <ShowForum />,
  },
  {
    path: "/forum/delete/:id",
    element: <DeleteForum />,
  },
  {
    path: "/forum/edit/:id",
    element: <EditForum />,
  },
  {
    path: "/forum/list",
    element: <ListForum />,
  },
]);

const routerLoggedOut = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/post/:id",
    element: <PostShow />,
  },
])

function App() {
  const auth = localStorage.getItem('user') ? JSON.stringify(localStorage.getItem('user')) : null
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <UserProvider>
            <React.StrictMode>
              <RouterProvider router={auth ? router : routerLoggedOut} />
            </React.StrictMode>
        </UserProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
