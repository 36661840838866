import * as React from 'react';
import Button from '@mui/material/Button';
import { Avatar, CircularProgress, Typography } from '@mui/material';
import { getComments } from '../../controllers/posts/comments';
import CommentReplyDrawer from './CommentReplyDrawer';
import { Add } from '@mui/icons-material';
import { DeletePost } from './card/PostActions';
import { UserContext } from '../../context/UserContext';

export default function SlimCommentDrawer({count, openCommentReply, setOpenCommentReply, id, token}) {
  const [comments, setComments] = React.useState([])
  const [content, setContent] = React.useState("")
  const [showReplies, setShowReplies] = React.useState([])
  const [reply_id, setReplyId] = React.useState(id)
  const messagesEndRef = React.createRef()
  const messagesRef = React.createRef()
  const userDetails = React.useContext(UserContext)

  const getAPIComments = async ( ) => {
    setComments(await getComments(id, token))
    setContent("")
  }

  React.useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
      scrollToBottom()
  }, [messagesEndRef])

  React.useEffect(() => {
    const getAPIComments = async ( ) => {
      setComments(await getComments(id, token))
      setContent("")
    }
    
    getAPIComments()
    setReplyId(id)
  }, [token, id])
  

  React.useEffect(() => {
    const reply_ids = []
    comments?.descendants?.filter(item => item.in_reply_to_id === id && reply_ids.push(item.in_reply_to_id))
    setShowReplies(reply_ids)
  }, [comments?.descendants, id])

  const repliesToggle = (id) => {
    if(showReplies.includes(id)){
      let removedReply = showReplies.filter(function(item) {
        return item !== id
      })
      setShowReplies(removedReply)
    } else {
      setShowReplies([...showReplies, id])
    }
  }
  
  const replyCount = (id) => {
    let count = comments?.descendants?.filter(item => item.in_reply_to_id === id).length
    return count
  }

  if(!comments){
    return (
      <CircularProgress />
    )
  }
  
  const comments_drawer = () => (
    <>
        {comments?.descendants?.length > 0 ?
          comments?.descendants?.map((comment, i) => 
            showReplies.includes(comment.in_reply_to_id) &&
              <div ref={ comment.id === comments?.descendants[comments?.descendants?.length-1].id ? messagesEndRef : messagesRef } key={i} style={comment.in_reply_to_id === id ? {display:'flex', alignItems:'center', marginBottom:15} : {display:'flex', alignItems:'center', marginBottom:15, marginLeft:50}}>
                <Avatar alt={comment.account.username} src={comment.account.avatar} style={{marginRight:10}} />
                <div style={{textAlign:'left'}}>
                  <span style={{display:'block', fontSize:14}}>@{comment.account.acct}</span>
                  <span style={{display:'block'}}>{comment.content.replaceAll(/(<([^>]+)>)/ig, '').replaceAll('&#39;', "'")}</span>
                  <Button style={{display:'block', cursor:'pointer', fontSize:14, borderStyle:'solid', width:'fit-content'}} onClick={() => repliesToggle(comment.id)}> {replyCount(comment.id)} Replies</Button>
                  <span onClick={() => {
                    setOpenCommentReply(true)
                    setContent("@"+comment.account.acct+" ")
                    setReplyId(comment.id)
                    }} style={{fontSize:14, color:'gray', cursor:'pointer'}}>Reply</span>
                </div>
                {userDetails?.id === comment.account.id &&
                <div style={{justifyContent:'flex-end'}}>
                  <DeletePost id={comment.id}  token={token} dontRedirect={getAPIComments} />
                </div>
                }
              </div>
            
            )
          :
          <Typography style={{textAlign:'center'}}>Be the first to comment</Typography>
        }
      <div className='comment-reply-drawer'>
        <Add color='primary' /><CommentReplyDrawer id={reply_id} token={token} content={content} open={openCommentReply} setContent={setContent} setOpen={setOpenCommentReply} getAPIComments={getAPIComments} />
      </div>
    </>

    )

  return (
    <>
    <p style={{fontWeight:'bold'}}>Group Chat</p>
    <div className='slim-comment-drawer' style={{}}>
        {comments_drawer()}
    </div>
    </>
  );
}